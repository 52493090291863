function Move ({ i, last, n, first_pos, last_pos, guess, reply, seq, rep, vsize, invalid, _show_invalids, is_complete, sharing, onShare, line_over_first, clue, clue_shown, clue_requested }) {
  const copy_button = (
    <button key='copy' className="copy" onClick={() => onShare(i)}>
      <svg height="20" width="20" fill="none">
        <path stroke="currentColor" d="M14.734 15.8974L19.22 12.1374C19.3971 11.9927 19.4998 11.7761 19.4998 11.5474C19.4998 11.3187 19.3971 11.1022 19.22 10.9574L14.734 7.19743C14.4947 6.9929 14.1598 6.94275 13.8711 7.06826C13.5824 7.19377 13.3906 7.47295 13.377 7.78743V9.27043C7.079 8.17943 5.5 13.8154 5.5 16.9974C6.961 14.5734 10.747 10.1794 13.377 13.8154V15.3024C13.3888 15.6178 13.5799 15.8987 13.8689 16.0254C14.158 16.1521 14.494 16.1024 14.734 15.8974Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
      </svg>
    </button>
  );
  const seq_div = <div className="seq">{(clue_requested ? "*" : (clue_shown ? "~" : "")) + ((clue_requested || clue_shown) && seq === "." ? "" : (rep === 1 ? seq : ""))}</div>
  const rep_div = <div className="rep">{rep}</div>
  const vsize_div = <div className="vsize">{vsize}+</div>
  console.log("guess: " + guess);
  console.log("last_pos: " + last_pos);
  return (
    <div className={"a" + (i === 0 && line_over_first ? " first" : "")} style={{ gridTemplateColumns: `repeat(${guess.length}, 1fr)` }}>
      {invalid ? <button key="close" className="close" onClick={() => _show_invalids(false)}>-</button> : <></>}
      {[...[...guess].keys()].map(j =>
        <div key={j} className={"b " + (n > 7 ? "shrink " : "") + reply[j]}>{guess[j]}{j === last_pos && is_complete() && ! sharing && (seq === "." || seq > 0) ? seq_div : null}{!last && j === first_pos && is_complete() && ! sharing && rep > 1 ? rep_div : null}{last && j === first_pos && is_complete() && ! sharing && vsize > -1 ? vsize_div : null}</div>
      )}
      {sharing ? copy_button : null}
    </div>
  );
}

export default Move;

