Array.prototype.last = function () { return this[this.length - 1]; };
Array.prototype.sum = function () { return this.reduce((a, e) => a + e, 0) };
Array.prototype.ks = function () { return [...this.keys()]; };
Array.prototype.kmap = function (f) { return [...this.keys()].map(f); };
Array.prototype.dup = function () { return this.kmap(i => Array.from(this[i])) };
Array.prototype.es = function () { return [...this.entries()]; };
Array.prototype.vs = function () { return [...this.values()]; };
Array.prototype.emap = function (f) { return [...this.entries()].map(f); };
Array.prototype.vmap = function (f) { return [...this.values()].map(f); };
Array.prototype.efilter = function (f) { return [...this.entries()].filter(f); };
Array.prototype.vfilter = function (f) { return [...this.values()].filter(f); };
Map.prototype.es = function () { return [...this.entries()]; };
Map.prototype.ks = function () { return [...this.keys()]; };
Map.prototype.emap = function (f) { return [...this.entries()].map(f); };
Map.prototype.efilter = function (f) { return [...this.entries()].filter(f); };

const az = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

function KeyBoard (origin, game, _game, word, _word, cursor, _cursor, tristate, _disabled, constraints, _constraints, _vsize, handle, _handle, share, _share, guessed, _guessed, end_game_feedback_ref, new_game_ref, init_word, init_cursor, is_complete, feature_unique, guessed_data, _show_invalids, fast, last_key, _last_key, feeding_back, _feeding_back, sharing, _sharing, shadow_word, feasibleLetters, infeasibleLetters, pendingLetters, unrestrictedLetters, getUnrestrictedLetters, complementUnrestrictedLetters, perms_result, perms_cache, clue, _clue, fetching_clue, _fetching_clue) {
  if (! game.n) return;
  const non_words = game.game_moves.filter((e) => e.reply === "Z".repeat(game.n)).map((e) => e.guess.trim());
  function cursor_at_beg () {
    return constraints.pos.slice(0, cursor).every(e => e.length === 1);
  }
  function next_cursor () {
    let rv = cursor + 1;
    const jump = constraints.pos.slice(cursor + 1).findIndex(e => e.length > 1);
    rv = cursor + (jump === -1 ? (game.n - cursor) : (jump + 1));
    if (rv === game.n) {
      const space_index = word.indexOf(' ');
      const dot_index = word.indexOf('.');
      if (space_index > -1 && dot_index > -1) {
        rv = Math.min(space_index, dot_index);
      } else if (space_index > -1) {
          rv = dot_index;
        rv = space_index
      } else if (dot_index > -1) {
        rv = dot_index;
      }
    }
    return rv;
  }
  function prev_cursor () {
    const jump = constraints.pos.slice(0, cursor).reverse().findIndex(e => e.length > 1);
    return cursor - (jump === -1 ? 0 : (jump + 1));
  }
  function is_green () {
    return constraints.pos[cursor].length === 1;
  }
  function is_green_i (i) {
    return constraints.pos[i].length === 1;
  }
  const merged_word = word.map((char, index) => char === ' ' ? shadow_word[index] : char);
  function constraints_violated () {
    const mc = constraints.min_counts;
    const ac = new Map();
    if (! merged_word.ks().every(i => merged_word[i] == ' ' || constraints.pos[i].includes(merged_word[i]))) {
      alert("unexpected 'pos error': please let us know");
      return true;
    }
    merged_word.map(e => { if (! ac.has(e)) ac.set(e, 0); ac.set(e, ac.get(e) + 1); });
    if (mc.ks().every(k => ac.has(k) && ac.get(k) >= mc.get(k))) {
      if (! constraints.capped.every(e => { return ac.has(e) === mc.has(e) && (! ac.has(e) || ac.get(e) === mc.get(e)); })) {
        return true;
      }
      return false;
    }
    return true;
  }
  this.disabled_space = () => sharing || cursor === game.n;
  this.disabled_delete = () => sharing || cursor === game.n || word[cursor] === ' ' || is_green();
  this.disabled_backspace = () => sharing || cursor_at_beg();
  this.disabled_escape = () => ! feeding_back && ! sharing && (cursor_at_beg() && word.every((e, i) => e === ' ' || constraints.pos[i].length === 1 && e === constraints.pos[i][0]));
  this.disabled_arrow_left = () => sharing || cursor_at_beg();
  this.disabled_arrow_right = () => sharing || cursor === game.n;
  this.disabled_digit = (e) => { if (sharing) return true; if (e === '$') return false; let i = Math.max(0, parseInt(e) - 1); return is_green_i(i); }
  this.disabled_letter = (k) => sharing || cursor === game.n || ! constraints.pos[cursor].includes(k) || is_green();
  this.disabled_question = () => sharing || is_complete() || clue != "" || ! this.disabled_enter();
  this.disabled_enter = () => sharing || is_complete() || merged_word.indexOf(' ') > -1 || merged_word.indexOf('.') > -1 || constraints_violated() || non_words.includes(merged_word.join('')) || feature_unique && guessed_data !== undefined;
  this.disabled_stab = () => sharing || is_complete() || word.indexOf(' ') == -1 || word.join('').trim().indexOf(' ') > -1 || word.join('').trim().length === 0 || word.indexOf('.') > -1 || constraints.pos.every((e, i) => e.length === 1 || word[i] === ' ') || non_words.includes(word.join('').trim()) || (!game.shared && guessed[word.join('').trim()] !== undefined) || perms_result !== null && perms_result.count === 0 || feature_unique && guessed_data !== undefined;
  this.letter_class = (k) => {
    const is_inactive_class = this.disabled_letter(k) ? "disabled " : ""
    if (sharing || ! constraints.min_counts.get(k) && constraints.capped.includes(k)) {
      return is_inactive_class + "nowhere";
    } else if (cursor === game.n || ! constraints.pos[cursor].includes(k)) {
      return is_inactive_class + "not_here";
    } else if (! constraints.pos[cursor].length === 1) {
      return is_inactive_class + "sure_here";
    } else if (infeasibleLetters.includes(k)) {
      return is_inactive_class + "infeasible";
    } else if (pendingLetters.includes(k)) {
      return is_inactive_class + "pending";
    } else if (!unrestrictedLetters.includes(k)) {
      return is_inactive_class + "pended";
    } else {
      return is_inactive_class + "maybe_here";
    }
  }
  this.space = () => { if (this.disabled_space()) return;
    word[cursor] = ' ';
    _word(Array.from(word));
    _cursor(next_cursor());
  }
  this.delete = () => { if (this.disabled_delete()) return;
    word[cursor] = ' ';
    _word(Array.from(word));
  }
  this.escape = () => { if (this.disabled_escape()) return;
    if (sharing) {
      _sharing(false);
      return;
    }
    if (feeding_back) {
      _feeding_back(false);
      return;
    }
    word.kmap(i => word[i] = ' ');
    constraints.pos.kmap(i => { if (constraints.pos[i].length === 1) word[i] = constraints.pos[i][0]; });
    _word(Array.from(word));
    const cursor_ = init_cursor(constraints.pos);
    _cursor(cursor_);
  }
  this.backspace = () => { if (this.disabled_backspace()) return;
    const cursor_ = prev_cursor();
    word[cursor_] = ' ';
    _word(Array.from(word));
    _cursor(cursor_);
  }
  this.arrow_left = () => { if (this.disabled_arrow_left()) return;
    _cursor(prev_cursor());
  }
  this.arrow_right = () => { if (this.disabled_arrow_right()) return;
    _cursor(next_cursor());
  }
  this.digit_dollar = (k, enabled_cb = () => {}) => { if (this.disabled_digit(k)) return;
    enabled_cb();
    if (k === '$') {
      _cursor(game.n);
      return;
    }
    let cursor_ = parseInt(k);
    if (cursor_ > game.n) cursor_ = game.n + 1;
    if (cursor_ > 0) cursor_ -= 1;
    while (constraints.pos[cursor_].length === 1) ++cursor_;
    _cursor(cursor_);
  }
  this.letter = (k, cb = () => {}, physical = true) => {
    const remaining = fast - (Date.now() - last_key.t);
    if (physical && remaining > 0) {
      if (last_key.disabled) {
        _last_key({ t: Date.now(), cursor: last_key.cursor, disabled: true, flash: false, remaining: remaining });
        return;
      }
      if (last_key.cursor < game.n - 1) {
        if (cursor != last_key.cursor + 1) {
          if (constraints.pos[last_key.cursor + 1].length === 1) {
            if (k === constraints.pos[last_key.cursor + 1][0]) {
              _last_key({ t: Date.now(), cursor: last_key.cursor + 1, disabled: false, flash: true, remaining: remaining });
              return;
            // } else if (! constraints.pos[cursor].includes(k)) {
              // _last_key({ t: Date.now(), cursor: last_key.cursor, disabled: true, flash: false, remaining: remaining });
              // return;
            } else if (last_key.flash) {
              _last_key({ t: Date.now(), cursor: last_key.cursor + 1, disabled: true, flash: false, flash_red: true, remaining: remaining });
              return;
            }
          }
        }
      }
    }
    _last_key({ t: Date.now(), cursor: cursor, disabled: true, flash: false, remaining: remaining });
    if (this.disabled_letter(k)) return;
    _last_key({ t: Date.now(), cursor: cursor, disabled: false, flash: false, remaining: remaining });
    if (k >= 'a' && k <= 'z' || k === '.') {
      word[cursor] = k;
      _word(Array.from(word));
      _cursor(next_cursor());
      cb();
    }
  }
  const make_move = (async (move_word) => {
      if (tristate.currentValue === 1) {
        console.log("found tristate 1");
      } else if (tristate.currentValue === 2) {
        console.log("fetching");
        tristate.currentValue = 1;
        _disabled(true);
        try {
          const o = (await (await fetch(origin + "/gamemove", { method: "POST", credentials: "include", body: move_word })).json()).o;
          await new Promise(r => setTimeout(r, 9));
          perms_cache.current.clear();
          _game(o.game);
          _clue(o.game.clue);
          const complemented_pos = complementUnrestrictedLetters(o.pos, getUnrestrictedLetters(o.pos, new Map(Object.entries(o.min_counts)), o.capped))
          _constraints({ pos: complemented_pos, min_counts: new Map(Object.entries(o.min_counts)), capped: o.capped });
          _vsize(o.vsize);
          _handle(o.handle);
          _share(o.share);
          _word(init_word(o.pos), true);
          _cursor(init_cursor(o.pos));
          const last_move = o.game.game_moves[o.game.game_moves.length - 1];
          if (last_move.reply != "Z".repeat(game.n)) {
            if (guessed.hasOwnProperty(last_move.guess.trim())) {
              guessed[last_move.guess.trim()].rep += 1;
            } else {
              guessed[last_move.guess.trim()] = { seq: last_move.seq, rep: 1 }
            }
          }
          setTimeout(() => {
            if (end_game_feedback_ref.current) { end_game_feedback_ref.current.focus(); }
            else if (new_game_ref.current) { new_game_ref.current.focus(); }
          }, 34);
        } catch (error) {
          console.log("error");
          await new Promise(r => setTimeout(r, 9));
          if (error.code === error.ABORT_ERR) {
            console.error("aborted fetch");
          } else {
            console.log(error);
          }
        } finally {
          tristate.currentValue = 2;
          _disabled(false);
        }
      } else {
        console.log("found tristate 0");
      }
    })
  this.question = (async () => { if (this.disabled_question()) return;
    if (fetching_clue) return;
    try {
      _fetching_clue(true);
      const clue = (await (await fetch(origin + "/clue", { method: "POST", credentials: "include", body: "" })).json()).o;
      _clue(clue);
    } catch (error) {
      console.log("error");
    } finally {
      _fetching_clue(false);
    }
  })
  this.enter = () => { if (this.disabled_enter()) return;
    make_move(merged_word.join(""));
  }
  this.stab = () => { if (this.disabled_stab()) return;
    make_move(word.join(""));
  }
}

function cx (msg) { console.log(msg); }
function cl (msg) { }

export default KeyBoard;

// todo
//   fast typing support for touch as well as keyboard
//   case of words finished

