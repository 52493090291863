import Compose from './Compose';
import Move from './Move';
import KeyBoard from './KeyBoard';
import { useState, useEffect, useRef } from "react";

const origin = window.location.hostname === "localhost" ? "http://localhost:8080" : "https://api." + window.location.hostname;
const fast = 400;
const is_viewing = /\/view\/([a-z0-9]+)(\/([0-9]+))?/.test(window.location.pathname);
const view_code = is_viewing ? [...window.location.pathname.matchAll(/\/view\/([a-z0-9]+)(\/([0-9]+))?/g)][0][1] : "";
let row_count = (is_viewing ? [...window.location.pathname.matchAll(/\/view\/([a-z0-9]+)(\/([0-9]+))?/g)][0][2] : -1);
row_count = (row_count === undefined || row_count === -1) ? -1 : parseInt(row_count.substring(1));

// Helper functions
const getUnrestrictedLetters = (pos, min_counts, capped) => {
  const restrictedLetters = new Set([...min_counts.keys(), ...capped]);
  const allLetters = new Set(pos.flat());
  const unrestrictedLetters = [...allLetters].filter(letter => !restrictedLetters.has(letter));
  return unrestrictedLetters;
};

const complementUnrestrictedLetters = (pos, unrestrictedLetters) => {
  return pos.map(choices => {
    const restrictedChoices = choices.filter(choice => !unrestrictedLetters.includes(choice));
    if (restrictedChoices.length < choices.length) {
      return [...choices, '.'];
    } else {
      return choices;
    }
  });
};

const replaceUnrestrictedLetters = (pos, unrestrictedLetters) => {
  return pos.map(choices => {
    const restrictedChoices = choices.filter(choice => !unrestrictedLetters.includes(choice));
    if (restrictedChoices.length === 0 && choices.length > 0) {
      return ['.'];
    } else if (restrictedChoices.length > 0 && restrictedChoices.length < choices.length) {
      return [...restrictedChoices, '.'];
    } else {
      return restrictedChoices;
    }
  });
};

const updatePosWithEnteredLetters = (word, pos) => {
  return pos.map((choices, idx) => {
    return word[idx] === ' ' ? [] : [word[idx]];
  });
};

const adjustConstraints = (word, constraints) => {
  const updatedMinCounts = new Map(constraints.min_counts);
  const enteredCounts = new Map();

  // Count the occurrences of each entered letter in the word
  word.forEach(char => {
    if (char !== ' ') {
      if (!enteredCounts.has(char)) {
        enteredCounts.set(char, 0);
      }
      enteredCounts.set(char, enteredCounts.get(char) + 1);
    }
  });

  // Adjust min_counts based on entered letters
  enteredCounts.forEach((count, char) => {
    if (updatedMinCounts.has(char)) {
      const newCount = updatedMinCounts.get(char) - count;
      if (newCount <= 0) {
        updatedMinCounts.delete(char);
      } else {
        updatedMinCounts.set(char, newCount);
      }
    }
  });

  return {
    ...constraints,
    min_counts: updatedMinCounts
  };
};

const substituteSpecialSymbol = (modifiedPos, unrestrictedLetters) => {
  return modifiedPos.map(choices => {
    if (choices.includes('.')) {
      return [...choices.filter(choice => choice !== '.'), ...unrestrictedLetters];
    } else {
      return choices;
    }
  });
};

// The function `updatePosWithValidLetters` remains the same as before

// Existing recursive function to generate valid permutations and update `modifiedPos`
const updatePosWithValidLetters = (choices, constraints, modifiedPos, blankPositions, letterCounts = new Map(), currentPermutation = [], idx = 0) => {
  if (idx === choices.length) {
    // Base case: update modifiedPos with valid letters in current permutation
    currentPermutation.forEach((choice, i) => {
      const posIndex = blankPositions[i];
      if (!modifiedPos[posIndex].includes(choice)) {
        modifiedPos[posIndex].push(choice);
      }
    });
    return 1;
  }

  let count = 0;

  for (const choice of choices[idx]) {
    const newLetterCounts = new Map(letterCounts);

    // Count the occurrences of the current choice in the new permutation
    if (!newLetterCounts.has(choice)) {
      newLetterCounts.set(choice, 0);
    }
    newLetterCounts.set(choice, newLetterCounts.get(choice) + 1);

    let valid = true;
    const remainingMinCounts = new Map(constraints.min_counts);

    // Check if the current permutation can satisfy the min_counts
    remainingMinCounts.forEach((minCount, letter) => {
      const remainingCount = minCount - (newLetterCounts.get(letter) || 0);
      if (remainingCount > 0 && choices.slice(idx + 1).flat().filter(c => c === letter).length < remainingCount) {
        valid = false;
      }
    });

    // Proceed with the next position if valid
    if (valid) {
      currentPermutation.push(choice);
      count += updatePosWithValidLetters(choices, constraints, modifiedPos, blankPositions, newLetterCounts, currentPermutation, idx + 1);
      currentPermutation.pop();
    }
  }

  return count;
};

const get_capped_violations = (word, constraints) => {
  const violations = [];
  const letterCounts = new Map();
  const unrestricted_letters = getUnrestrictedLetters(constraints.pos, constraints.min_counts, constraints.capped)

  // Count the occurrences of each letter in 'word'
  word.forEach((char) => {
    if (char !== ' ') {
      if (!letterCounts.has(char)) {
        letterCounts.set(char, 0);
      }
      letterCounts.set(char, letterCounts.get(char) + 1);
      if (unrestricted_letters.includes(char) && char !== '.') {
        if (!letterCounts.has('.')) {
          letterCounts.set('.', 0);
        }
        letterCounts.set('.', letterCounts.get('.') + 1);
      }
    }
  });

  const max_unrestricted = word.length - Array.from(constraints.min_counts.values()).sum();

  if ((letterCounts.get('.') || 0) > max_unrestricted) {
    word.forEach((char, idx) => {
      if (unrestricted_letters.includes(char)) {
        violations.push(idx);
      }
    });
  }

  // Identify capped violations
  constraints.capped.forEach((letter) => {
    const currentCount = letterCounts.get(letter) || 0;
    const minCount = constraints.min_counts.get(letter) || 0;
    if (currentCount > minCount) {
      // Highlight all occurrences of the capped letter that is in violation
      word.forEach((char, idx) => {
        if (char === letter) {
          violations.push(idx);
        }
      });
    }
  });

  return violations;
};

const get_mc_violations = (word, constraints) => {
  const violations = [];
  const letterCounts = new Map();
  let blanks = 0;

  // Count the occurrences of each letter in 'word' and count empty spaces
  word.forEach((char) => {
    if (char === ' ') {
      blanks++;
    } else {
      if (!letterCounts.has(char)) {
        letterCounts.set(char, 0);
      }
      letterCounts.set(char, letterCounts.get(char) + 1);
    }
  });

  // Calculate excess capped spaces
  let excessCapped = 0;
  constraints.capped.forEach((letter) => {
    const currentCount = letterCounts.get(letter) || 0;
    const minCount = constraints.min_counts.get(letter) || 0;
    if (currentCount > minCount) {
      excessCapped += (currentCount - minCount);
    }
  });

  // Calculate total required spaces for all letters in min_counts
  let totalRequiredSpaces = 0;
  constraints.min_counts.forEach((minCount, letter) => {
    const currentCount = letterCounts.get(letter) || 0;
    totalRequiredSpaces += Math.max(0, minCount - currentCount);
  });

  // Check if blanks + excessCapped is less than totalRequiredSpaces
  if (blanks + excessCapped < totalRequiredSpaces) {
    // Identify the violating positions
    letterCounts.forEach((currentCount, letter) => {
      const minCount = constraints.min_counts.get(letter) || 0;
      if (currentCount > minCount) {
        word.forEach((char, idx) => {
          if (char === letter && constraints.pos[idx].length > 1 && !violations.includes(idx)) {
            violations.push(idx);
          }
        });
      }
    });
  }

  return violations;
};

// Function to generate subsets of a given size
const generateSubsets = (array, size) => {
  const result = [];
  const generate = (subset, start) => {
    if (subset.length === size) {
      result.push([...subset]);
      return;
    }
    for (let i = start; i < array.length; i++) {
      subset.push(array[i]);
      generate(subset, i + 1);
      subset.pop();
    }
  };
  generate([], 0);
  return result;
};

function App() {
  // State and references initialization
  const tristate = useRef(0);
  const [initializing, _initializing] = useState(true);
  const [feeding_back, _feeding_back] = useState(false);
  const [sending_feedback, _sending_feedback] = useState(false);
  const [sharing, _sharing] = useState(false);
  const [hide_i, _hide_i] = useState(0);
  const [disabled, _disabled] = useState(false);
  const [game, _game] = useState({});
  const [clue, _clue] = useState("");
  const [fetching_clue, _fetching_clue] = useState(false);
  const [guessed, _guessed] = useState({});
  const [constraints, _constraints] = useState({});
  const [vsize, _vsize] = useState(-1);
  const [handle, _handle] = useState("");
  const [share, _share] = useState("");
  const [feature_unique, _feature_unique] = useState(false);
  const [cursor, _cursor] = useState(0);
  const [word, _word] = useState([]);
  const [original_word, _original_word] = useState([]);
  const [last_key, _last_key] = useState({ t: 0 });
  const [caps, _caps] = useState(false);
  const [show_invalids, _show_invalids] = useState(true);
  const [moves, _moves] = useState(0);
  const [key_strokes, _key_strokes] = useState(0);
  const [touch_strokes, _touch_strokes] = useState(0);
  const [key_jumps, _key_jumps] = useState(0);
  const [click_jumps, _click_jumps] = useState(0);
  const [pre_share, _pre_share] = useState([]);
  const [share_entangled, _share_entangled] = useState(true);
  const [exhausted, _exhausted] = useState(0);
  const new_game_ref = useRef();
  const end_game_feedback_ref = useRef();
  const end_game_escape_feedback_ref = useRef();
  const end_game_escape_share_ref = useRef();
  const perms_cache = useRef(new Map());
  const fetching = useRef(false);
  const pending = useRef(new Array());
  const cache_version = useRef(0);
  const cache = useRef(new Map());
  const cacher = (new_word, clear_and_skip = false) => {
    _word(new_word)
    if (clear_and_skip) {
      cache.current.clear();
      pending.current = new Array();
      _exhausted(0);
      cache_version.current = cache_version.current + 1;
      cl("cache_version: " + cache_version.current);
      return;
    }
    const word_str = new_word.join("");
    if (word_str === " ".repeat(game.n) || /^[a-z]*$/.test(word_str)) {
      _exhausted(0);
      return;
    }
    if (cache.current.has(word_str)) {
      _exhausted(cache.current.get(word_str));
    } else {
      if (fetching.current) {
        cl("pushing: " + new_word);
        pending.current.push(Array.from(new_word));
      } else {
        _exhausted(-1);
        const f = (w) => {
          w = w.filter(e => !cache.current.has(e))
          w.map(e => cache.current.set(e, -1));
          cl("fetching: " + w);
          fetching.current = true;
          const fetch_cache_version = cache_version.current;
          fetch(`${origin}/exhausted`, { method: "POST", credentials: "include", headers: { "Content-Type": "application/json" }, body: JSON.stringify(w) })
            .then(r => r.json())
            .then(r => {
              if (fetch_cache_version === cache_version.current) {
                r.o.map((e, i) => {
                  cl("caching: " + w[i]);
                  cache.current.set(w[i], e);
                  if (word.join("") === w[i]) {
                    _exhausted(e);
                  }
                })
              } else {
                cl("skipping cache because version changed, fetch_cache_version = " + fetch_cache_version + ", cache_version.current = " + cache_version.current);
              }
              fetching.current = false;
              if (pending.current.length > 0) {
                const pending_words = Array.from(pending.current.map(e => e.join("")));
                pending.current = new Array();
                cl("caching pending: " + pending_words);
                f(pending_words);
              }
            })
            .catch(error => {
              console.error("Error checking exhausted:", error);
              w.map(e => cache.current.clear(e));
              fetching.current = false;
              w.map(e => cache.current.delete(e));
            });
        }
        f([word_str]);
      }
    }
  }

  const updatePosBasedOnConstraints = (word, constraints, cappedViolations, mcViolations) => {

    if (cappedViolations.length > 0 || mcViolations.length > 0) {
      return { count: 0, modifiedPos: [] }
    }

    if (word.join() === original_word.join()) {
      return null;
    }

    // Step 1: Identify unrestricted letters
    const u = getUnrestrictedLetters(constraints.pos, constraints.min_counts, constraints.capped);

    // Step 1.1: Return cached if possible
    const key = word.map(c => u.includes(c) ? '.' : c).join()
    if (perms_cache.current.has(key)) {
      return perms_cache.current.get(key);
    }

    // Step 2: Replace unrestricted letters with a special symbol in the copy of `pos`
    const replacedPos = replaceUnrestrictedLetters(constraints.pos, u);

    // Step 3: Modify pos based on entered letters
    const modifiedPos = updatePosWithEnteredLetters(word, replacedPos);

    // Step 4: Adjust constraints based on entered letters
    const adjustedConstraints = adjustConstraints(word, constraints);

    // Step 5: Generate valid permutations and update modifiedPos
    // Compute blank positions accurately by checking if choices are empty
    const blankPositions = modifiedPos.map((choices, idx) => choices.length === 0 ? idx : -1).filter(idx => idx !== -1);
    const blankChoices = blankPositions.map(idx => replacedPos[idx]);

    const count = updatePosWithValidLetters(blankChoices, adjustedConstraints, modifiedPos, blankPositions);

    // Step 6: Substitute the special symbol with the group of letters it originally replaced
    const finalPos = substituteSpecialSymbol(modifiedPos, u);

    console.log("perms count for word: " + word + ": " + count);
    perms_cache.current.set(key, { count: count, modifiedPos: finalPos });
    return perms_cache.current.get(key);
  };

  // Function to check for feasible permutations
  const hasFeasiblePermutations = (word, constraints) => {
    const { count } = updatePosBasedOnConstraints(word, constraints, [], []);
    return count > 0;
  };

  // Function to find all subsets of the smallest size to fix
  const findAllSmallestSubsetsToFix = (word, constraints) => {
    const filledPositions = word.map((char, idx) => {
      return (char !== ' ' && constraints.pos[idx].length > 1) ? idx : -1;
    }).filter(idx => idx !== -1);

    let smallestSize = filledPositions.length + 1;
    let smallestSubsets = [];

    for (let size = 1; size <= filledPositions.length; size++) {
      const subsets = generateSubsets(filledPositions, size);
      let foundFeasibleSubset = false;

      for (const subset of subsets) {
        const testWord = [...word];
        subset.forEach(pos => {
          testWord[pos] = ' ';
        });

        if (hasFeasiblePermutations(testWord, constraints)) {
          if (size < smallestSize) {
            smallestSize = size;
            smallestSubsets = [subset];
          } else if (size === smallestSize) {
            smallestSubsets.push(subset);
          }
          foundFeasibleSubset = true;
        }
      }

      if (foundFeasibleSubset) {
        break;
      }
    }

    return smallestSubsets; // Return all subsets of the smallest size
  };

  const highlightErrorPositions = (word, constraints, cursor) => {
    const errorSubsets = findAllSmallestSubsetsToFix(word, constraints);

    let errorPositions;
    let errorPositionsInAllSubsets = [];
    let errorPositionsInNotAllSubsets = [];
    if (errorSubsets.some(subset => subset.includes(cursor))) {
      errorPositions = new Set(errorSubsets.filter(subset => subset.includes(cursor)).flat());
      errorPositionsInAllSubsets = new Set(Array.from(errorPositions).filter(position => errorSubsets.filter(subset => subset.includes(cursor)).every(subset => subset.includes(position))))
      errorPositionsInNotAllSubsets = errorPositions.difference(errorPositionsInAllSubsets);
    } else {
      errorPositions = new Set(errorSubsets.flat());
      errorPositionsInAllSubsets = new Set(Array.from(errorPositions).filter(position => errorSubsets.filter(subset => subset.includes(cursor)).every(subset => subset.includes(position))))
      errorPositionsInNotAllSubsets = errorPositions.difference(errorPositionsInAllSubsets);
    }

    return [Array.from(errorPositions), Array.from(errorPositionsInAllSubsets), Array.from(errorPositionsInNotAllSubsets)];
  };

  // Helper functions and event handlers
  const is_complete = () => game.game_moves.length >= 1 && game.game_moves[game.game_moves.length - 1].reply === "G".repeat(game.n);
  const guessed_data = (guessed === undefined || game.shared) ? undefined : guessed[word.join("")];
  const word_str = word.join("");

  const on_keyup = (e) => {
    if (e.repeat || e.altKey || e.ctrlKey) { }
    else if (e.metaKey) { }
    else if (e.key === "CapsLock") { _caps(false); }
  };

  const on_keydown = (e) => {
    if (is_viewing) { }
    else if (e.repeat || e.altKey || e.ctrlKey) { }
    else if (e.metaKey && e.key === "Backspace") { kb.delete(); }
    else if (e.metaKey) { }
    else if (e.key === "CapsLock") { _caps(true); }
    else if (e.key === "Escape") { kb.escape(); }
    else if (e.key === "Backspace") { kb.backspace(); }
    else if (e.key === "ArrowLeft" || e.key === "<") { kb.arrow_left(() => _moves(moves + 1)); }
    else if (e.key === "ArrowRight" || e.key === ">") { kb.arrow_right(() => _moves(moves + 1)); }
    else if (e.key === "Enter") { kb.enter(); }
    else if (e.key === "?") { kb.question(); }
    else if (e.key === "^") { kb.stab(); }
    else if (e.key.length > 1) { }
    else if (e.key === "*") { }
    else if (e.key === "-") { _show_invalids(! show_invalids); }
    else if (e.key === " ") { kb.space(); }
    else if (e.key >= '0' && e.key <= '9' || e.key === '$') { kb.digit_dollar(e.key, () => _key_jumps(key_jumps + 1)); }
    else { kb.letter(e.key.toLowerCase(), () => _key_strokes(key_strokes + 1), true); }
  };

  function init_cursor (pos) {
    const first = pos.findIndex(e => e.length > 1);
    return first === -1 ? pos.length : first;
  }

  function init_word (pos) {
    const t = Array.from(Array(pos.length).keys()).map(i => pos[i].length === 1 ? pos[i][0] : ' ');
    _original_word(Array.from(t));
    return t;
  }

  useEffect(() => {
    (async () => {
      if (tristate.currentValue === 2) {
        window.addEventListener("keydown", on_keydown);
        window.addEventListener("keyup", on_keyup);
      } else if (tristate.currentValue === 1) {
      } else {
        console.log("fetching");
        tristate.currentValue = 1;
        _disabled(true);
        try {
          let o = null;
          if (is_viewing) {
            o = (await (await fetch(origin + "/view", { method: "POST", credentials: "include", body: view_code })).json()).o;
          } else {
            o = (await (await fetch(origin + "/currentgame", { method: "POST", credentials: "include" })).json()).o;
            _guessed(o.guessed);
          }
          await new Promise(r => setTimeout(r, 9));
          perms_cache.current.clear();
          _game(o.game);
          _clue(o.game.clue);
          const complemented_pos = complementUnrestrictedLetters(o.pos, getUnrestrictedLetters(o.pos, new Map(Object.entries(o.min_counts)), o.capped))
          _constraints({ pos: complemented_pos, min_counts: new Map(Object.entries(o.min_counts)), capped: o.capped });
          _vsize(o.vsize);
          _handle(o.handle);
          _share(o.share);
          _feature_unique(o.feature_unique);
          _word(init_word(o.pos));
          _cursor(init_cursor(o.pos));
          _initializing(false);
          tristate.currentValue = 2;
          _disabled(false);
          setTimeout(() => {
            if (end_game_feedback_ref.current && game.shared && !game.shared.show) { end_game_feedback_ref.current.focus(); }
            else if (new_game_ref.current) { new_game_ref.current.focus(); }
          }, 34);
          console.log("ok");
        } catch (error) {
          console.log("error");
          tristate.currentValue = 0;
          if (error.code === error.ABORT_ERR) {
            console.error("aborted fetch");
          } else {
            console.log(error);
          }
        }
      }
    })();
    if (constraints.pos !== undefined && constraints.pos.vfilter(v => v.length > 1).length === 1 && word.every(e => e !== ' ')) {
      cl("auto firing enter");
      _cursor(constraints.pos.findIndex(v => v.length > 1));
      kb.enter();
    }
    return () => {
      window.removeEventListener("keydown", on_keydown);
      window.removeEventListener("keyup", on_keyup);
    }
  });

  if (initializing) {
    return (
      <div>
        <p>initializing</p>
      </div>
    );
  }

  // Fetch new game
  const fetch_new_game = async () => {
    if (tristate.currentValue === 1) {
    } else if (tristate.currentValue === 2) {
      console.log("fetching");
      tristate.currentValue = 1;
      _disabled(true);
      try {
        const o = (await (await fetch(origin + "/newgame", { method: "POST", credentials: "include" })).json()).o;
        await new Promise(r => setTimeout(r, 9));
        perms_cache.current.clear();
        _game(o.game);
        _clue(o.game.clue);
        _guessed(o.guessed);
        const complemented_pos = complementUnrestrictedLetters(o.pos, getUnrestrictedLetters(o.pos, new Map(Object.entries(o.min_counts)), o.capped))
        _constraints({ pos: complemented_pos, min_counts: new Map(Object.entries(o.min_counts)), capped: o.capped });
        _vsize(o.vsize);
        _handle(o.handle);
        _share(o.share);
        _word(init_word(o.pos));
        _cursor(init_cursor(o.pos));
        _sharing(false);
        setTimeout(() => {
          if (end_game_feedback_ref.current && game.shared && !game.shared.show) { end_game_feedback_ref.current.focus(); }
          else if (new_game_ref.current) { new_game_ref.current.focus(); }
        }, 34);
        console.log("ok");
      } catch (error) {
        console.log("error");
        if (error.code === error.ABORT_ERR) {
          console.error("aborted fetch");
        } else {
          console.log(error);
        }
      } finally {
        tristate.currentValue = 2;
        _disabled(false);
      }
    } else {
    }
  };

  // Calculating capped and minimum count violations
  const cappedViolations = get_capped_violations(word, constraints);
  const mcViolations = get_mc_violations(word, constraints);
  const perms_result = updatePosBasedOnConstraints(word, constraints, cappedViolations, mcViolations);
  const shadow_word = Array(word.length).fill(' ');
  let permViolations = [[], [], []];
  if (perms_result !== null) {
    if (perms_result.count === 0) {
      permViolations = highlightErrorPositions(word, constraints, cursor);
      console.log("Error positions to fix:", permViolations);
    } else {
      perms_result.modifiedPos.forEach((choices, idx) => {
        if (choices.length === 1 && word[idx] === ' ') {
          shadow_word[idx] = choices[0];
        }
      });
    }
  }

  // Function to check if placing a letter at the current cursor position yields feasible permutations
  const checkFeasibleLettersAtCursor = (word, pos, constraints, cursor, unrestrictedLetters) => {
    // Return empty sets if the cursor is at game.n
    if (cursor === game.n) {
      return { feasibleLetters: [], infeasibleLetters: [] };
    }

    const allowedLetters = constraints.pos[cursor].filter(c => c === '.' || !unrestrictedLetters.includes(c));
    const feasibleLetters = [];
    const infeasibleLetters = [];

    allowedLetters.forEach(letter => {
      const testWord = [...word];
      testWord[cursor] = letter;

      const testWordCappedViolations = get_capped_violations(testWord, constraints);
      const testWordMcViolations = get_mc_violations(testWord, constraints, cappedViolations);

      const { count } = word.join() === original_word.join() ? { count: 1 } : updatePosBasedOnConstraints(testWord, constraints, testWordCappedViolations, testWordMcViolations);
      if (count === 0 || count === 1 && testWord.every(e => e !== ' ') && (testWordCappedViolations.length > 0 || testWordMcViolations.length > 0)) {
        if (letter === '.') {
          unrestrictedLetters.forEach(letter => infeasibleLetters.push(letter));
        } else {
          infeasibleLetters.push(letter);
        }
      } else {
        if (letter === '.') {
          unrestrictedLetters.forEach(letter => feasibleLetters.push(letter));
        } else {
          feasibleLetters.push(letter);
        }
      }
    });

    return { feasibleLetters, infeasibleLetters, cappedViolations };
  };

  // Usage
  const unrestrictedLetters = getUnrestrictedLetters(constraints.pos, constraints.min_counts, constraints.capped);
  const { feasibleLetters, infeasibleLetters } = checkFeasibleLettersAtCursor(word, constraints.pos, constraints, cursor, unrestrictedLetters);
  const ac = new Map();
  word.map(e => { if (! ac.has(e)) ac.set(e, 0); ac.set(e, ac.get(e) + 1); });
  const pendingLetters = [...constraints.min_counts.keys()].filter(k => constraints.min_counts.get(k) > ac.get(k) || ac.get(k) === undefined);

  const kb = new KeyBoard(origin, game, _game, word, cacher, cursor, _cursor, tristate, _disabled, constraints, _constraints, _vsize, handle, _handle, share, _share, guessed, _guessed, end_game_feedback_ref, new_game_ref, init_word, init_cursor, is_complete, feature_unique, guessed_data, _show_invalids, fast, last_key, _last_key, feeding_back, _feeding_back, sharing, _sharing, shadow_word, feasibleLetters, infeasibleLetters, pendingLetters, unrestrictedLetters, getUnrestrictedLetters, complementUnrestrictedLetters, perms_result, perms_cache, clue, _clue, fetching_clue, _fetching_clue);

  // Game logic and rendering
  game.valid_moves = game.game_moves.filter((e) => e.reply !== "Z".repeat(game.n));
  const no_invalids = game.valid_moves.length === game.game_moves.length;
  let invalids_button = no_invalids ? <></> : <button key='invalids' className={"meta gapped_row"} disabled={sharing || no_invalids} onClick={(e) => { _show_invalids(!show_invalids); }} style={{ gridColumnStart: 5, gridColumnEnd: 6 }}>-</button>
  let digits = <>
    {['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].map((e, i) => { if (parseInt(e) <= game.n) { return <button key={e} className="meta gapped_row" disabled={kb.disabled_digit(e)} onClick={(_) => kb.digit_dollar(e)} style={{ gridColumnStart: Math.round((20 - game.n) / 2) + i - 1, gridColumnEnd: Math.round((20 - game.n) / 2) + i + 0 }}>{e}</button> } })}
    {['$'].map((e, i) => { if (e === '$' || parseInt(e) <= game.n) { return <button key={e} className="meta gapped_row" disabled={sharing} onClick={(_) => kb.digit_dollar(e)} style={{ gridColumnStart: Math.round((20 - game.n) / 2) + game.n + 0, gridColumnEnd: Math.round((20 - game.n) / 2) + game.n + 1 }}>{e}</button> } })}
  </>;

  if (key_strokes < 5 || key_jumps - (click_jumps + moves) > 5 || (click_jumps + moves) === 0 && key_strokes > 20 || key_strokes > 100) {
    digits = <></>;
  }

  // Feedback, sharing, and buttons
  const on_pre_feedback = async (_) => {
    _feeding_back(true)
    _pre_share((await (await fetch(origin + "/preshare", { mode: 'cors', method: "POST", credentials: "include", headers: { "Content-Type": "application/json" }, body: JSON.stringify({}) })).json()).o);
  };

  const on_pre_share = async (_) => {
    _show_invalids(false)
    _sharing(true)
    _hide_i(game.valid_moves.length)
    _pre_share((await (await fetch(origin + "/preshare", { mode: 'cors', method: "POST", credentials: "include", headers: { "Content-Type": "application/json" }, body: JSON.stringify({}) })).json()).o);
  };

  const on_share = async (i, _sharing, _hide_i) => {
    _hide_i(i);
    const available_shares = pre_share.filter(e => e.k.i === i && share_entangled === e.k.entangled);
    let url = window.location.origin + "/";
    if (available_shares.length === 1) {
      url += available_shares[0].kk;
    } else {
      const uninitialized_share = pre_share.filter(e => e.k.i === -1)[0];
      url += uninitialized_share.kk;
      await (await fetch(origin + "/share", { mode: 'cors', method: "POST", credentials: "include", headers: { "Content-Type": "application/json" }, body: JSON.stringify({ k: uninitialized_share.kk, i: i, entangled: share_entangled }) })).json();
    }
    navigator.share({ url: url, text: "Let's solve this!", title: "WordChase: A Word Game" })
    setTimeout(() => _sharing(false), 333);
  }

  const on_feedback = async (message) => {
    _sending_feedback(true);
    try {
      await (await fetch(origin + "/feedback", { mode: 'cors', method: "POST", credentials: "include", headers: { "Content-Type": "application/json" }, body: JSON.stringify({ k: game.shared.share.k, feedback: message }) })).json();
      setTimeout(() => { if (end_game_feedback_ref.current) end_game_feedback_ref.current.disabled = true; }, 1);
      _feeding_back(false);
      setTimeout(() => { if (new_game_ref.current) new_game_ref.current.focus(); }, 34);
    } catch (error) {
    } finally {
      _sending_feedback(false);
    }
  }

  // Rendering UI elements
  let caps_class = (caps ? " caps" : "");
  let letter_class = "letter" + caps_class;
  const share_button = (
    <button key='share' disabled={sharing} className="meta svg gapped_row" onClick={on_pre_share} style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
      <svg height="20" width="20" fill="none">
        <path stroke="currentColor" d="M14.734 15.8974L19.22 12.1374C19.3971 11.9927 19.4998 11.7761 19.4998 11.5474C19.4998 11.3187 19.3971 11.1022 19.22 10.9574L14.734 7.19743C14.4947 6.9929 14.1598 6.94275 13.8711 7.06826C13.5824 7.19377 13.3906 7.47295 13.377 7.78743V9.27043C7.079 8.17943 5.5 13.8154 5.5 16.9974C6.961 14.5734 10.747 10.1794 13.377 13.8154V15.3024C13.3888 15.6178 13.5799 15.8987 13.8689 16.0254C14.158 16.1521 14.494 16.1024 14.734 15.8974Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
      </svg>
    </button>
  );
  const entangle_button = (
    <button key='entangle' disabled={!sharing} className={"meta svg gapped_row sharing" + (!share_entangled ? " dim" : " bright")} onClick={() => _share_entangled(!share_entangled)} style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
      <img src="infinity-part.png" height="30" />
    </button>
  );
  const enter_button = (
    <button key='enter' className="meta enter gapped_row" disabled={kb.disabled_enter() || disabled} onClick={(_) => kb.enter()} style={{ gridColumnStart: 17, gridColumnEnd: 20 }}>
      <svg height="20" width="20" viewBox="0 0 1024 1024" fill="currentColor"><path stroke="currentColor" d="M864 192c-19.2 0-32 12.8-32 32v224c0 89.6-70.4 160-160 160H236.8l105.6-105.6c12.8-12.8 12.8-32 0-44.8s-32-12.8-44.8 0l-160 160c-3.2 3.2-6.4 6.4-6.4 9.6-3.2 6.4-3.2 16 0 25.6 3.2 3.2 3.2 6.4 6.4 9.6l160 160c6.4 6.4 12.8 9.6 22.4 9.6s16-3.2 22.4-9.6c12.8-12.8 12.8-32 0-44.8L236.8 672H672c124.8 0 224-99.2 224-224V224c0-19.2-12.8-32-32-32z" strokeWidth="100" /></svg>
    </button>
  );
  const stab_button = (
    <button key='stab' className="meta stab gapped_row" disabled={kb.disabled_stab() || disabled} onClick={(_) => kb.stab()} style={{ gridColumnStart: 20, gridColumnEnd: 21 }}>^</button>
  );

  // Keyboard and Compose components
  const keyboard = (
    <div className={"keyboard" + (clue === "" ? " noclue" : " clue")} onClick={(e) => e.target.blur()}>
      {['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'].map((e, i) => <button key={e} className={letter_class + " " + kb.letter_class(e) + " gapped_row"} disabled={false} onClick={(_) => { _touch_strokes(touch_strokes + 1); kb.letter(e); }} style={{ gridColumnStart: i * 2 + 1, gridColumnEnd: i * 2 + 3 }}>{e}</button>)}
      {['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'].map((e, i) => <button key={e} className={letter_class + " " + kb.letter_class(e)} disabled={false} onClick={(_) => { _touch_strokes(touch_strokes + 1); kb.letter(e); }} style={{ gridColumnStart: i * 2 + 1, gridColumnEnd: i * 2 + 3 }}>{e}</button>)}
      <button key='backspace' className="meta" disabled={kb.disabled_backspace()} onClick={(_) => kb.backspace()} style={{ gridColumnStart: 19, gridColumnEnd: 21, gridRowStart: 2, gridRowEnd: 4 }}>⌫</button>
      {['z', 'x', 'c', 'v', 'b', 'n', 'm'].map((e, i) => <button key={e} className={letter_class + " " + kb.letter_class(e)} disabled={false} onClick={(_) => { _touch_strokes(touch_strokes + 1); kb.letter(e); }} style={{ gridColumnStart: i * 2 + 1, gridColumnEnd: i * 2 + 3 }}>{e}</button>)}
      {['.'].map((e, i) => <button key='dot' className={letter_class + " " + kb.letter_class(e)} disabled={false} onClick={(_) => { _touch_strokes(touch_strokes + 1); kb.letter(e); }} style={{ gridColumnStart: 15, gridColumnEnd: 17 }}>{e}</button>)}
      <button key='delete' className="meta enlarge" disabled={kb.disabled_delete()} onClick={(_) => kb.delete()} style={{ gridColumnStart: 17, gridColumnEnd: 19 }}>␡</button>
      {sharing ? entangle_button : share_button}
      <button key='caps' className={(caps ? "" : "caps ") + "meta gapped_row"} disabled={sharing} onClick={(_) => _caps(!caps)} style={{ gridColumnStart: 3, gridColumnEnd: 5 }}>a</button>
      {invalids_button}
      <button key='space' className="meta space gapped_row" disabled={kb.disabled_space()} onClick={(_) => kb.space()} style={{ gridColumnStart: 6, gridColumnEnd: 10 }}>_</button>
      <button key='arrow_left' className="meta gapped_row" disabled={kb.disabled_arrow_left()} onClick={(_) => kb.arrow_left()} style={{ gridColumnStart: 10, gridColumnEnd: 12 }}>&lt;</button>
      <button key='escape' className={"meta gapped_row enlarge" + (sharing ? " sharing" : "")} disabled={kb.disabled_escape()} onClick={(_) => kb.escape()} style={{ gridColumnStart: 12, gridColumnEnd: 14 }}>␛</button>
      <button key='arrow_right' className="meta gapped_row" disabled={kb.disabled_arrow_right()} onClick={(_) => kb.arrow_right()} style={{ gridColumnStart: 14, gridColumnEnd: 16 }}>&gt;</button>
      <button key='question' className={"meta question gapped_row" + (fetching_clue ? " paused" : "")} disabled={kb.disabled_question()} onClick={(_) => kb.question()} style={{ gridColumnStart: 16, gridColumnEnd: 17 }}>?</button>
      {enter_button}
      {stab_button}
      {digits}
    </div>
  );

  const clue_div = (
    <div className="clue">{clue}</div>
  );

  const compose = (
    <>
      {keyboard}
      {clue_div}
      <Compose
        i={game.valid_moves.length}
        n={game.n}
        hide_n={game.valid_moves.some(e => e.guess.search("  ") === -1)}
        cursor={cursor}
        word={word}
        shadow_word={shadow_word}
        move={(i) => { if (constraints.pos[i].length > 1) { _click_jumps(click_jumps + 1); _cursor(i) } }}
        disabled={disabled}
        guessed_data={guessed_data}
        exhausted={exhausted}
        fast={fast}
        last_key={last_key}
        _last_key={_last_key}
        sharing={sharing}
        onShare={(i) => on_share(i, _sharing, _hide_i)}
        cappedViolations={cappedViolations}
        permViolations={permViolations}
      />
    </>
  );
  const inactive_compose = <Compose i={game.valid_moves.length} n={game.n} hide_n={game.valid_moves.some(e => e.guess.search("  ") === -1)} cursor={cursor} word={word} shadow_word={shadow_word} move={(i) => { }} disabled={disabled} guessed_data={guessed_data} exhausted={exhausted} fast={fast} last_key={last_key} _last_key={_last_key} sharing={sharing} onShare={(i) => { }} cappedViolations={cappedViolations} permViolations={permViolations} />
  const end_game_feedback = (
    <button key='end_game_feedback' ref={end_game_feedback_ref} disabled={(game.shared && game.shared.show) || feeding_back || sharing} className="end_game minor left" onClick={(_) => { on_pre_feedback(); setTimeout(() => { if (end_game_escape_feedback_ref.current) { end_game_escape_feedback_ref.current.focus(); } }, 0); }}>
      <svg height="20" width="20" fill="none" transform="scale(-1, 1)" style={{ WebkitTransform: "scale(-1, 1)" }}>
        <path stroke="currentColor" d="M14.734 15.8974L19.22 12.1374C19.3971 11.9927 19.4998 11.7761 19.4998 11.5474C19.4998 11.3187 19.3971 11.1022 19.22 10.9574L14.734 7.19743C14.4947 6.9929 14.1598 6.94275 13.8711 7.06826C13.5824 7.19377 13.3906 7.47295 13.377 7.78743V9.27043C7.079 8.17943 5.5 13.8154 5.5 16.9974C6.961 14.5734 10.747 10.1794 13.377 13.8154V15.3024C13.3888 15.6178 13.5799 15.8987 13.8689 16.0254C14.158 16.1521 14.494 16.1024 14.734 15.8974Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
      </svg>
    </button>
  );
  const end_game_escape_feedback = <button key='end_game_escape_feedback' ref={end_game_escape_feedback_ref} className="end_game minor left" disabled={kb.disabled_escape()} onClick={(_) => kb.escape()}>&times;</button>
  const end_game_share = (
    <button key='end_game_share' disabled={sharing || feeding_back} className="end_game minor" onClick={(_) => { on_pre_share(); setTimeout(() => { if (end_game_escape_share_ref.current) { end_game_escape_share_ref.current.focus(); } }, 0); }}>
      <svg height="20" width="20" fill="none">
        <path stroke="currentColor" d="M14.734 15.8974L19.22 12.1374C19.3971 11.9927 19.4998 11.7761 19.4998 11.5474C19.4998 11.3187 19.3971 11.1022 19.22 10.9574L14.734 7.19743C14.4947 6.9929 14.1598 6.94275 13.8711 7.06826C13.5824 7.19377 13.3906 7.47295 13.377 7.78743V9.27043C7.079 8.17943 5.5 13.8154 5.5 16.9974C6.961 14.5734 10.747 10.1794 13.377 13.8154V15.3024C13.3888 15.6178 13.5799 15.8987 13.8689 16.0254C14.158 16.1521 14.494 16.1024 14.734 15.8974Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
      </svg>
    </button>
  );
  const feedback_emoji = (
    <>
      <button key="feedback_emoji_1" className="feedback_emoji" disabled={sending_feedback} onClick={() => on_feedback('🙂')}>🙂</button>
      <button key="feedback_emoji_2" className="feedback_emoji" disabled={sending_feedback} onClick={() => on_feedback('😐')}>😐</button>
      <button key="feedback_emoji_3" className="feedback_emoji" disabled={sending_feedback} onClick={() => on_feedback('😥')}>😥</button>
      <button key="feedback_emoji_4" className="feedback_emoji" disabled={sending_feedback} onClick={() => on_feedback('😒')}>😒</button>
    </>
  );
  let feedback_message = <></>;
  let has_title = false;
  let title = <></>;
  const title_width = game.n * (game.n > 7 ? 38 : 48);
  if (game.shared && is_complete()) {
    feedback_message = <label key="feedback_message" className="end_game minor feedback left" disabled={false}>{game.shared.feedback}</label>
    title = <div className="title" style={{width: title_width + "px"}}><div className="handle"><span className="at">@</span>{handle}</div><div className="share"><span className="slash">/</span>{game.shared.share.k}</div></div>
    has_title = true;
  } else if (!is_viewing && share != "" && is_complete()) {
    title = <div className="title" style={{width: title_width + "px"}}><div className="handle"><span className="at">@</span>{handle}</div><div className="share"><span className="slash">/</span>{share}</div></div>
    has_title = true;
  }
  const end_game_escape_share = <button key='end_game_escape_share' ref={end_game_escape_share_ref} className="end_game minor" disabled={kb.disabled_escape()} onClick={(_) => kb.escape()}>&times;</button>
  const new_game = <button key="new_game" ref={new_game_ref} className="end_game" onClick={fetch_new_game} disabled={disabled || sharing}>&#x2192;</button>
  const end_game = (
    <div key="end_game_div" className="end_game_div">
      {game.shared ? (feeding_back ? end_game_escape_feedback : (game.shared.show ? feedback_message : end_game_feedback)) : null}
      {feeding_back ? feedback_emoji : new_game}
      {sharing ? end_game_escape_share : end_game_share}
    </div>
  );
  const invalid = (show_invalids && game.game_moves.length !== game.valid_moves.length && !game.shared && share == "") ? (
    <div key="invalid" className="invalid">
      {game.game_moves.filter((e) => e.reply === "Z".repeat(game.n)).map((e, i) => <Move i={0} last={false} n={game.n} first_pos={[...e.guess.matchAll("[a-z]")][0].index} last_pos={[...e.guess.matchAll("[a-z]")].last().index} key={"invalid_" + e.guess} guess={e.guess} reply={e.reply} seq={0} rep={0} vsize={-1} invalid={true} _show_invalids={_show_invalids} is_complete={is_complete} sharing={false} onShare={(i) => on_share(i, _sharing, _hide_i)} line_over_first={false} clue="" clue_shown={false} clue_requested={false} />)}
    </div>
  ) : null;
  const valid_or_invalid_moves = Array.from((is_complete() ? game.valid_moves : (show_invalids ? game.game_moves : game.valid_moves)));
  const moves_to_show = valid_or_invalid_moves.slice(0, (row_count < 0 || row_count > valid_or_invalid_moves.length) ? valid_or_invalid_moves.length : row_count);
  const bottom = is_viewing ? [<div key="spacer" className="spacer" />, (is_complete() ? null : (row_count == -1 || row_count > moves_to_show.length ? inactive_compose : null))] : (is_complete() ? [invalid, end_game] : compose);
  const top_spacer = <div className="spacer" />;
  const sliver = <div className="sliver" />;

  return (
    <>
      {bottom}
      {moves_to_show.reverse().map((e, i) => (
        <Move
          i={game.valid_moves.length - i - 1}
          last={i === 0}
          n={game.n}
          first_pos={[...e.guess.matchAll("[a-z]")][0].index}
          last_pos={[...e.guess.matchAll("[a-z]")].last().index}
          key={"guess_" + i}
          guess={sharing ? (hide_i <= game.valid_moves.length - i - 1 ? " ".repeat(game.n) : e.guess) : e.guess}
          reply={e.reply}
          seq={game.valid_moves[game.valid_moves.length - i - 0] === undefined ? e.seq : (e.seq + 1 == game.valid_moves[game.valid_moves.length - i - 0].seq ? "." : e.seq)}
          rep={e.rep}
          vsize={i === 0 ? vsize : -1}
          invalid={e.reply === "Z".repeat(game.n)}
          _show_invalids={_show_invalids}
          is_complete={is_complete}
          sharing={sharing}
          onShare={(i) => on_share(i, _sharing, _hide_i)}
          line_over_first={is_viewing && row_count < 0 || !is_viewing && has_title}
          clue={e.wflr_clue.clue}
          clue_shown={e.wflr_clue.clue_shown}
          clue_requested={e.wflr_clue.clue_requested}
        />
      ))}
      {is_viewing ? null : title}
      {is_viewing && row_count == -1 ? sliver : top_spacer}
    </>
  );
}

function cl (msg) { console.log(msg); }

export default App;

